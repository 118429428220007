/* eslint-disable camelcase */
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { getApolloClient } from 'api/apolloClient';
import { NotFoundError } from 'api/errorHandling';
import { CardInfo } from 'interfaces/RecommendsWidget';
import { APIParameters } from './common';

const GET_RV_SAVINGS_MMA = gql`
query getSavingsMMA (
  $prodType: [SavingsMmaProductType!], $zip: String, $depositAmount: Float, $page: Int, $pageSize: Int,
  $listingType: ListingType, $ignoreBudget: Boolean, $pid: String
) {
  savings_mma_rates(
  	product_type: $prodType,
    zipcode: $zip,
    deposit_amount: $depositAmount,
    page: $page,
    page_size: $pageSize,
    listing_type: $listingType,
    ignore_budget: $ignoreBudget,
    pid: $pid
  ) {
    institution {
      name
      logo_url
      reviews {
        overall_rating
        short_summary
        website_url
        link
      }
      insurance_information {
        type
        disclosure
      }
    }
    product {
      name
      type
    }
    is_paid
    is_national
    is_local
    advertiser_notes
    apy
    effective_at
    min_to_open
    max_to_open
    rate
    ep_url
    pixel_url
  }
}
`;

interface reviews {
  overall_rating: number | null;
  short_summary: string | null;
  website_url: string | null;
  link: string | null;
}

export interface internalRVSavingsMMAResponse {
  institution: {
    name: string;
    logo_url: string;
    reviews: reviews;
    insurance_information: {
      type: string;
      disclosure: string;
    };
  };
  product: {
    name: string;
    type: string;
  };
  is_paid: boolean;
  is_national: boolean;
  is_local: boolean;
  advertiser_notes: string;
  apy: number;
  effective_at: string;
  min_to_open: number;
  max_to_open: number | null;
  rate: number;
  ep_url: string;
  pixel_url: string;
}

export interface RVSavingsMMAResponse {
  savings_mma_rates: internalRVSavingsMMAResponse[];
}

const setSavingsMMA = (toMap: RVSavingsMMAResponse) => {
  const insideToMap = toMap.savings_mma_rates;
  const mapped: CardInfo[] = insideToMap.map(
    ({
      institution: {
        name: institutionName,
        logo_url: institutionLogo,
        reviews: { overall_rating: rating, short_summary: offerDetails, link: reviewLink },
        insurance_information: { disclosure: insuranceDisclosure },
      },
      product: { name: productName },
      apy,
      min_to_open: minToOpen,
      ep_url: nextUrl,
      effective_at: effectiveAt,
      advertiser_notes: advertiserNote,
      is_paid: isPaid,
    }: internalRVSavingsMMAResponse) => ({
      advertiserNote,
      apy,
      effectiveAt,
      institutionLogo,
      institutionName,
      insuranceDisclosure,
      isPaid,
      minToOpen,
      nextUrl,
      offerDetails,
      productName,
      rating,
      reviewLink,
    }),
  ) as CardInfo[];
  return mapped;
};

async function getSavingsMMA({ prodType, zip, depositAmount, page, pageSize }: APIParameters) {
  const apolloClient: ApolloClient<NormalizedCacheObject> = getApolloClient();
  const response = await apolloClient.query({
    context: { clientName: 'redVentures' },
    query: GET_RV_SAVINGS_MMA,
    variables: {
      depositAmount,
      prodType,
      zip,
      ...(page && { page }),
      ...(pageSize && { pageSize }),
      ignoreBudget: false,
      listingType: 'ALL',
      pid: 'myfi_fortune_api',
    },
  });
  const errorLabel = '[getSavingsMMA]';
  if (response.errors) {
    throw new Error(`${errorLabel} ${JSON.stringify(response.errors)}`);
  }
  if (!response.data) {
    throw new NotFoundError(`${errorLabel} Could not find offers.`);
  }
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return setSavingsMMA(response.data as RVSavingsMMAResponse);
  } catch (e) {
    throw new Error(`${errorLabel} ${(e as Error).stack}`);
  }
}

export default getSavingsMMA;
