import { ChangeEvent } from 'react';
import { InputProps } from './types';
import * as S from './InputStyles';

const Input = ({
  id = '',
  type = 'text',
  placeholder = '',
  assistiveText,
  label,
  value,
  className = '',
  required = false,
  size = { default: 'large' },
  variant = 'default',
  inputmode,
  disabled,
  minLength,
  maxLength,
  min,
  max,
  step,
  pattern,
  dataCy,
  onChange,
  onKeyDown,
  onKeyUp,
  onBlur,
}: InputProps) =>
  (label || assistiveText ? (
    <S.InputWrapper className={className}>
      {label && (
        <S.Label
          htmlFor={id}
          aria-required={required}
        >
          {label} {required && <span>*</span>}
        </S.Label>
      )}
      <S.Input
        $size={size}
        $variant={variant}
        id={id}
        placeholder={placeholder}
        value={value}
        type={type}
        inputMode={inputmode}
        onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        onKeyUp={onKeyUp}
        disabled={disabled}
        minLength={minLength}
        maxLength={maxLength}
        min={min}
        max={max}
        step={step}
        pattern={pattern}
      />
      {assistiveText && <S.Text className={`assistive-text ${className}`}>{assistiveText}</S.Text>}
    </S.InputWrapper>
  ) : (
    <S.Input
      $size={size}
      $variant={variant}
      id={id}
      placeholder={placeholder}
      value={value}
      type={type}
      step={step}
      inputMode={inputmode}
      onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
      onKeyDown={onKeyDown}
      disabled={disabled}
      className={className}
      data-cy={dataCy}
    />
  ));

export default Input;
