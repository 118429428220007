/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';
import { breakpoint } from 'styles/globals';
import { Theme, getBodyStyle, getColor } from 'styles/theme';
import { arrowRight, arrowLeft } from 'styles/icons';

export const Wrapper = styled.nav`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const List = styled.ol`
  display: flex;

  @media (${breakpoint.lgMax}) {
    display: none;
  }

  &.tablet-and-mobile {
    @media (${breakpoint.lgMin}) {
      display: none;
    }

    @media (${breakpoint.lgMax}) {
      display: flex;
    }
  }
`;

interface CrumbWrapperProps {
  $firstItem?: boolean;
  $lastItem?: boolean;
  theme: Theme;
}
const arrowSizes = css`
  border-width: 0 2px 2px 0;
  padding: 4.5px;
`;

const simpleArrow = css<{ theme: Theme }>`
  ${arrowLeft}
  ${arrowSizes}
  @media (${breakpoint.lgMin}) {
    ${arrowRight}
    ${arrowSizes}
  }
`;

export const CrumbWrapper = styled.li<CrumbWrapperProps & { theme: Theme }>`
  display: flex;
  text-align: center;
  position: relative;
  ${getBodyStyle('graphikCond', { default: 'small' }, 'semiBold')};


  &:not(:nth-child(1)) {
    padding-left: 5px;

    .styled-custom-link {
      margin-left: 10px;
    }

    &::before {
      margin-top: 5px;
    }
  }

  &:last-child {
    padding-left: 15px;

    @media (${breakpoint.lgMin}) {
      padding-left: 33px;
    }

    &::before {
      position: absolute;
      left: 5px;
      margin-top: 5px;
    }
    &::after {
      margin-top: 0;
    }
  }

  &::before {
    content: '';
    display: ${(props) => (props.$firstItem ? 'none' : 'inline-flex;')};
    
    ${({ $firstItem }) => !$firstItem && css<{ theme: Theme }>`${simpleArrow}`};

    margin: 4px 0 0;
    color:  ${getColor('neutralDarkGray')};
    align-self: flex-start;
    margin: 5px 8px 0 0;
  }
  &::after {
    color: ${getColor('neutralDarkGray')};
    ${({ $lastItem }) =>
    $lastItem &&
      css<{ theme: Theme }>` 
        content: '';
        position: absolute;
        ${simpleArrow}
        padding:5px;
        left: 13px;
        top: 5px;
    `};
   
  }
`;

interface CrumbProps {
  $last?: boolean;
  theme: Theme;
}

export const Crumb = styled.span<CrumbProps>`
  text-transform: uppercase;
  line-height: 21px;
  letter-spacing: 0.5px;
  ${(props) =>
    (!props.$last ?
      css`
        transition: all 0.25s;
        white-space: nowrap;
      ` :
      css<{ theme: Theme }>`
        color: ${getColor('textSecondary')};;
        text-align: left;
      `)}
`;
