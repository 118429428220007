import { useState, KeyboardEvent, useEffect } from 'react';
// import Dropdown from 'components/Dropdown';
import Dropdown from 'components/Globals/Base/Dropdown';
import Input from 'components/Globals/Base/Input';
import Label from 'components/Label';
import { savingsAccountTypes, minTermLengths, maxTermLengths } from 'constants/constants';
import { trackEvent } from 'services/Gtm/functions';
import { EventData } from 'services/Gtm/entities';
import { isValidUSZipcode } from 'api/RedVentures/getUserZipCode';
import { Filters } from './WidgetStyles';

export interface FilterBy {
  zipCode: string;
  accountType: string;
  depositAmount: number;
  minTermLength: number;
  maxTermLength: number;
}

export type RecommendsWidgetInputType = keyof FilterBy;

interface WidgetInputProps {
  userZipCode: string;
  inputs: RecommendsWidgetInputType[];
  onChange: (filters: FilterBy) => void;
  trackingData: EventData;
}

const WidgetInput = ({ userZipCode, inputs, onChange, trackingData }: WidgetInputProps) => {
  const [filters, setFilters] = useState<FilterBy>({
    accountType: 'MMA,SAVINGS',
    depositAmount: 25000,
    maxTermLength: 36,
    minTermLength: 6,
    zipCode: userZipCode,
  });
  const [zipCodeInvalid, setZipcodeInvalid] = useState<boolean>(false);

  const validateZipCode = (value: string) => {
    const isValid = isValidUSZipcode(value);
    if (isValid && zipCodeInvalid) setZipcodeInvalid(false);
    if (!isValid && !zipCodeInvalid) setZipcodeInvalid(true);
  };

  useEffect(() => {
    const newFilters = { ...filters, zipCode: userZipCode };
    setFilters(newFilters);
    validateZipCode(userZipCode);
    if (isValidUSZipcode(userZipCode)) onChange(newFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userZipCode]);

  const inputsSet = new Set(inputs);

  return (
    <Filters className={inputs.length === 3 ? 'singleLine' : 'multiLine'}>
      {inputsSet.has('accountType') && (
        <Dropdown
          selected={filters.accountType || 'Account type'}
          label='Account type'
          list={savingsAccountTypes.map((option) => ({
            label: option.name,
            value: option.value,
          }))}
          onOptionClick={(value) => {
            trackEvent({
              contentPlacementCD: 'Recommends Widget',
              eventAction: 'Input Interaction Dropdown',
              eventCategory: 'Recommends Widget',
              ...trackingData,
            });
            const newFilters = { ...filters, accountType: value };
            setFilters(newFilters);
            if (!zipCodeInvalid) onChange(newFilters);
          }}
        />
      )}
      {inputsSet.has('zipCode') && (
        <div data-cy='zipCode'>
          <Label htmlFor='zipCode'> ZIP Code </Label>
          <Input
            id='zipCode'
            className={`${zipCodeInvalid ? 'error' : ''}`}
            placeholder=''
            value={`${filters.zipCode || ''}`}
            onChange={(value) => {
              trackEvent({
                contentPlacementCD: 'Recommends Widget',
                eventAction: 'Input Interaction Zipcode',
                eventCategory: 'Recommends Widget',
                ...trackingData,
              });
              setFilters({ ...filters, zipCode: value });
              validateZipCode(value);
            }}
            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Enter' && !zipCodeInvalid) onChange(filters);
            }}
          />
          {zipCodeInvalid && <span className='errorMessage'>Invalid US zip code</span>}
        </div>
      )}
      {inputsSet.has('depositAmount') && (
        <div data-cy='depositAmount'>
          <Label htmlFor='depositAmount'> Deposit amount </Label>
          <Input
            id='depositAmount'
            className={`${!filters.depositAmount.toString() ? 'error' : ''}`}
            placeholder=''
            inputmode='numeric'
            value={filters.depositAmount.toString()}
            onChange={(value) => {
              trackEvent({
                contentPlacementCD: 'Recommends Widget',
                eventAction: 'Input Interaction Deposit',
                eventCategory: 'Recommends Widget',
                ...trackingData,
              });
              const amount = parseInt(value || '0', 10);
              if (!Number.isNaN(amount)) {
                setFilters({ ...filters, depositAmount: amount || 0 });
              }
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !zipCodeInvalid) onChange(filters);
            }}
          />
          <span
            className='dollarSign'
            aria-hidden='true'
          >
            $
          </span>
        </div>
      )}
      {inputsSet.has('minTermLength') && (
        <Dropdown
          label='Min. Term Length'
          selected={`${filters.minTermLength}`}
          list={minTermLengths.map((option) => ({
            label: option.name,
            value: option.value.toString(),
          }))}
          onOptionClick={(value) => {
            const newFilters = { ...filters, minTermLength: parseInt(value, 10) };
            setFilters(newFilters);
            if (!zipCodeInvalid) onChange(newFilters);
          }}
          dataCy='minTermLength'
        />
      )}
      {inputsSet.has('maxTermLength') && (
        <Dropdown
          label='Max. Term Length'
          selected={`${filters.maxTermLength}`}
          list={maxTermLengths
            .filter((option) => option.value >= filters.minTermLength)
            .map((option) => ({
              label: option.name,
              value: option.value.toString(),
            }))}
          onOptionClick={(value) => {
            const newFilters = { ...filters, maxTermLength: parseInt(value, 10) };
            setFilters(newFilters);
            if (!zipCodeInvalid) onChange(newFilters);
          }}
          dataCy='maxTermLength'
        />
      )}
    </Filters>
  );
};

export default WidgetInput;
