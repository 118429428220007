import { useEffect, useState, ChangeEvent } from 'react';
import * as S from './PaginationArrowNumericStyle';

export interface PaginationArrowNumericProps {
  totalCount: number;
  currentPage: number;
  recordsPerPage: number;
  onPageChange: (value: number) => void;
  className?: string;
  trackEventFunction?: (arrowName: string) => void;
}

export const paginate = (records: any[], page: number, limit: number) => {
  const startIndex = (page - 1) * limit;
  const endIndex = page * limit;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return records.slice(startIndex, endIndex);
};

const PaginationArrowNumeric = ({
  totalCount,
  recordsPerPage,
  currentPage,
  className,
  onPageChange,
  trackEventFunction,
}: PaginationArrowNumericProps) => {
  const [pageNum, setPageNum] = useState<number | null>(currentPage);

  const totalPageCount = Math.ceil(totalCount / recordsPerPage) || 1;

  const checkValidPageNumber = (num: number | null) => num && num > 0 && num <= totalPageCount;

  useEffect(() => {
    setPageNum(currentPage);
  }, [currentPage]);

  return (
    <S.PaginationWrapper className={className}>
      <button
        type='button'
        aria-label='Previous page'
        className='previous'
        disabled={currentPage <= 1}
        onClick={() => {
          if (trackEventFunction) trackEventFunction('Previous');
          onPageChange(currentPage - 1);
        }}
        data-cy='previousPage'
      >
        <S.PreviousArrow />
      </button>
      <span>
        Page
        <input
          value={pageNum || ''}
          inputMode='numeric'
          aria-label='Edit page number'
          aria-invalid={!checkValidPageNumber(pageNum)}
          className={!checkValidPageNumber(pageNum) ? 'error' : ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const pageNo = e.target.value ? parseInt(e.target.value, 10) : null;
            if (!pageNo || !Number.isNaN(pageNo)) {
              setPageNum(pageNo);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && checkValidPageNumber(pageNum)) onPageChange(pageNum || 1);
          }}
        />
        of {totalPageCount}
      </span>
      <button
        type='button'
        aria-label='Next page'
        className='next'
        disabled={currentPage >= totalPageCount}
        onClick={() => {
          if (trackEventFunction) trackEventFunction('Next');
          onPageChange(currentPage + 1);
        }}
        data-cy='nextPage'
      >
        <S.NextArrow />
      </button>
    </S.PaginationWrapper>
  );
};
export default PaginationArrowNumeric;
