import { ReactElement, ReactNode } from 'react';
import LabelWrapper from './LabelStyles';

export interface LabelProps {
  className?: string;
  children?: ReactElement | ReactElement[] | string | ReactNode;
  htmlFor: string;
  required?: boolean;
}

const Label = ({ htmlFor, required = false, className = '', children }: LabelProps) => (
  <LabelWrapper
    className={`label ${className}`}
    htmlFor={htmlFor}
  >
    {children}
    {required && <span className={`${required ? 'required' : ''}`}>* required</span>}
  </LabelWrapper>
);

export default Label;
