import styled, { css } from 'styled-components';
import { breakpoint } from 'styles/globals';
import { Theme, getColor, getBodyStyle } from 'styles/theme';
import orderBreakpoints from 'utils/miscUtils/orderBreakpoints';
import { InputSizes } from './types';

export const inputSizes: InputSizes = {
  large: css`
    height: 56px;
    padding: 16px;
  `,
  small: css`
    height: 40px;
    padding: 8px 16px;
  `,
};

const handleVariant = (variant: string) => {
  if (variant === 'discrete') {
    return css`
          border-width: 0 0 1px 0;
          padding: 8px;
        `;
  }
  return ``;
};

export const InputWrapper = styled.div<{ theme: Theme }>`
  display: flex;
  flex-direction: column;
  gap: 4px;

  &.error {
    input {
      border: 1px solid ${getColor('semanticsError')};
    }

    span,
    label {
      color: ${getColor('textAccentSecondary')};
    }

  }
  &.disabled {
    input {
      border: 1px solid ${getColor('neutralLightGray3')};
    }

    span,
    label {
      color: ${getColor('textSecondary')};
    }
  }
`;

export const Label = styled.label<{ theme: Theme }>`
  ${getBodyStyle('graphikCond', { default: 'default' }, 'regular')}
  line-height: 24px;
  letter-spacing: 0.5px;
  color: ${getColor('textPrimary')};

  span {
    color: ${getColor('textAccentSecondary')};

    &.disclaimer {
      color: ${getColor('textPrimary')}
    }
  }
`;

export const Input = styled.input<{
  $size: { default: keyof InputSizes; [key: string]: keyof InputSizes };
  $variant: 'default' | 'discrete';
  theme: Theme;
}>`
  ${getBodyStyle('graphikCond', { default: 'default' })}
  line-height: 24px;
  border: 1px solid ${({ $variant }) =>
    ($variant === 'discrete' ? getColor('borderPrimary') : getColor('borderTertiary'))};

  ::placeholder, 
  ::-moz-placeholder {
    color: ${getColor('textSecondary')};
  }

  &:hover {
    border: 1px solid ${getColor('borderAccentPrimary')};
    ${({ $variant }) => handleVariant($variant)};
  }

  &:focus-visible, &:focus, &:active {
    box-shadow: 0 0 5px 0 ${getColor('textLink')};
    outline: none;
  }

  &:valid {
    color: ${getColor('textPrimary')};
  }

  &:disabled {
    border: 1px solid ${getColor('neutralLightGray3')};
  }

  ${({ $size }) => {
    const { default: defaultSize, ...customSizes } = $size || { default: 'large' };
    const breakpoints = orderBreakpoints(customSizes) as { [key: string]: keyof InputSizes };

    return css`
      ${inputSizes[defaultSize]};
      ${Object.keys(breakpoints).map(
    (bkp: string) => css`
          @media (${breakpoint[bkp]}) {
            ${inputSizes[breakpoints[bkp]]};
          }
      `,
  )}
    `;
  }}

  ${({ $variant }) => handleVariant($variant)};
`;

export const Text = styled.span<{ theme: Theme }>`
  ${getBodyStyle('graphikCond', { default: 'xsmall' }, 'regular')}
  letter-spacing: 0.5;
  color: ${getColor('textPrimary')};
  line-height: 18px;

  &.disabled {
    color: ${getColor('textSecondary')};
  }

  &.error {
    color: ${getColor('textAccentSecondary')};
  }
`;
