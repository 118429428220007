/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';
import { font } from 'styles/globals';
import { getColor, Theme } from 'styles/theme';

const iconSize = '24px';
const absoluteCenter = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const InfoToggletipWrapper = styled.div<{ theme: Theme; $position: string | undefined; $colorVar: string }>`
  position: relative;
  width: ${iconSize};
  height: ${iconSize};
  cursor: pointer;
  z-index: 3;

  &:hover, &:focus, &:focus-within {
    z-index: 4;
  }
  
  & > svg {
    width: 14px;
    height: 14px;
    ${absoluteCenter}
    path {
      fill: ${(props) => `${props.$colorVar}`};
    }
  }

  &:focus:before, &:hover:before {
    content: '';
    width: ${iconSize};
    height: ${iconSize};
    background: ${(props) => `${props.$colorVar}1A`};
    border-radius: 50%;
    ${absoluteCenter}
  }

  &:focus:before {
    background: ${(props) => `${props.$colorVar}33`};
  }

  &:focus:after, &:hover:after {
    position: absolute;
    z-index: 3;
    font-family : ${font.graphikCompact};
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    padding: 8px;
    width: 175px;
    background-color: ${getColor('surfaceWhite')};
    box-shadow: 2px 2px 4px ${getColor('neutralBlack')}26;
    color: ${getColor('textPrimary')};
    content: attr(data-tooltip);
    ${(props) => {
    switch (props.$position) {
      case 'bottom':
        return `
          left: 50%;
          top: 0;
          transform: translate(-50%, ${iconSize});
          border-top: 4px solid ${props.$colorVar};
        `;
      case 'right':
        return `
          left: 0;
          top: 50%;
          transform: translate(${iconSize}, -50%);
          border-left: 4px solid ${props.$colorVar};
        `;
      case 'bottom-right':
        return `
          left: 0;
          top: 0;
          transform: translate(-2px, ${iconSize});
          border-top: 4px solid ${props.$colorVar};
        `;
      case 'bottom-left':
        return `
          right: 0;
          top: 0;
          transform: translate(2px, ${iconSize});
          border-top: 4px solid ${props.$colorVar};
        `;
      case 'left':
        return `
          right: 0;
          top: 50%;
          transform: translate(-${iconSize}, -50%);
          border-right: 4px solid ${props.$colorVar};
        `;
      case 'top':
      default:
        return `
          left: 50%;
          bottom: 0%;
          transform: translate(-50%, -${iconSize});
          border-bottom: 4px solid ${props.$colorVar};
        `;
    }
  }}
  }
`;
