/* eslint-disable import/prefer-default-export */
import { css } from 'styled-components';
import { color } from './globals';

export const lists = css`
  ul {
    padding-inline-start: 16px;
    list-style: outside;
  }

  ol {
    padding-inline-start: 16px;
  }

  li {
    list-style-position: outside;
    margin-bottom: 8px;
  }

  li::marker {
    color: ${color.darkGray};
  }
`;

export const bulletList = css`
  ul, ol {
    padding-inline-start: 16px;
    list-style: outside;
  }

`;
