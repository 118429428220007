const buildAdminBarUrls = (pageId: number, hostUrl: string) => {
  const environment = hostUrl.split('.')[0];
  let dashboardUrl;
  let editUrl;

  switch (true) {
    case environment === 'fortune':
      dashboardUrl = 'https://content.fortune.com/wp-admin';
      editUrl = `${dashboardUrl}/post.php?post=${pageId}&action=edit`;
      break;
    case environment === 'dev-frontend':
      dashboardUrl = 'https://content-develop.fortune.com/wp-admin';
      editUrl = `${dashboardUrl}/post.php?post=${pageId}&action=edit`;
      break;
    case environment === 'qa-frontend':
      dashboardUrl = 'https://content-qa.fortune.com/wp-admin';
      editUrl = `${dashboardUrl}/post.php?post=${pageId}&action=edit`;
      break;
    case environment === 'staging-frontend':
      dashboardUrl = 'https://content-staging.fortune.com/wp-admin';
      editUrl = `${dashboardUrl}/post.php?post=${pageId}&action=edit`;
      break;
    default:
      dashboardUrl = 'https://content.fortune.com/wp-admin';
      editUrl = `${dashboardUrl}/post.php?post=${pageId}&action=edit`;
  }

  return { dashboardUrl, editUrl };
};

export default buildAdminBarUrls;
