import { bluePrimaryLink, redLink } from 'components/Globals/Base/Link/LinkStyles';
import { css } from 'styled-components';
import { Theme } from './theme';

const articleContentAnchorStyle = css<{ theme: Theme }>`
  a:not(.styled-custom-link) {
    ${bluePrimaryLink};
  }

  .evergreen a {
    ${redLink};
  }

  a:not(.styled-custom-link), 
  .evergreen a {
    // to keep consistency with the article's font style
    font-weight: 400;
    letter-spacing: 0.5px;
    line-height: 25px;
  }
`;

export default articleContentAnchorStyle;
