import { cdRatesInfo, savingsAccountRatesInfo } from 'constants/constants';
import getArticlePageTestId from 'constants/testsIds/articlePage';
import RecommendsWidget, { RecommendsWidgetProps } from './RecommendsWidget';

type WidgetProps = Omit<RecommendsWidgetProps, 'widgetTitle' | 'inputs' | 'outputs'>;

export const SavingsWidget = (props: WidgetProps) => (
  <RecommendsWidget
    {...props}
    widgetTitle='The best savings account rates from our partners for '
    inputs={['accountType', 'depositAmount', 'zipCode']}
    outputs={savingsAccountRatesInfo}
    type={getArticlePageTestId('SAVINGS_WIDGET')}
  />
);

export const CDRatesWidget = (props: WidgetProps) => (
  <RecommendsWidget
    {...props}
    widgetTitle='Best CD Rates for '
    inputs={['depositAmount', 'zipCode', 'minTermLength', 'maxTermLength']}
    outputs={cdRatesInfo}
    type={getArticlePageTestId('CD_RATES_WIDGET')}
  />
);
