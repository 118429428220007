import useBreakpoint from 'hooks/useBreakpoint';
import { formatDate } from 'utils/dateUtils';
import { CardInfo, Offering, OutputsInfo } from 'interfaces/RecommendsWidget';
import { EventData } from 'services/Gtm/entities';
import { CardWrapper } from './WidgetStyles';
import {
  InstitutionInfo,
  InfoItemTooltips,
  InfoItemValues,
  InstitutionImage,
  OfferingInfoSection,
  PromotedOffer,
} from './WidgetElements';

interface RatesCardProps {
  className?: string;
  data: CardInfo;
  widgetColor: string;
  outputs: OutputsInfo;
  depositAmount: number;
  type: string;
  trackingData: EventData;
  postId: number;
}

const formatTerm = (term: number) => {
  if (term % 12 !== 0) {
    return `${term}mo`;
  }
  return `${Math.round(term / 12)}yr`;
};

const RatesCard = ({
  className,
  data,
  widgetColor,
  depositAmount,
  outputs,
  type,
  trackingData,
  postId,
}: RatesCardProps) => {
  const isMobile = useBreakpoint('mdMax');

  const {
    institutionLogo,
    institutionName,
    insuranceDisclosure,
    apy,
    productName,
    minToOpen,
    rating,
    nextUrl,
    advertiserNote,
    effectiveAt,
    offerDetails,
    isPaid,
    reviewLink,
    term,
  } = data;

  const offerings: { [key: string]: Offering } = {
    apy: {
      date: formatDate('MMMM D, YYYY', new Date(effectiveAt)),
      dateLabel: 'Effective at date',
      info: '',
      title: '',
      value: apy ? `${apy.toFixed(2)} %` : '',
      valueLabel: 'APY value',
    },
    estEarnings: {
      date: 'Over 1 Year',
      dateLabel: 'EST. Earnings period',
      info: '',
      title: '',
      value: `$ ${Math.round(depositAmount * (apy / 100))}`,
      valueLabel: 'EST. Earnings value',
    },
    minBalance: {
      date: '',
      dateLabel: '',
      info: '',
      title: '',
      value: `$ ${minToOpen}`,
      valueLabel: 'Minimum balance for APY value',
    },
    minDeposit: {
      date: '',
      dateLabel: '',
      info: '',
      title: '',
      value: `$ ${minToOpen}`,
      valueLabel: 'Minimum deposit value',
    },
    term: {
      date: '',
      dateLabel: '',
      info: '',
      title: '',
      value: formatTerm(term),
      valueLabel: 'Term value',
    },
  };

  const offeringValues: Offering[] = Object.keys(outputs)
    .filter((key) => key !== 'featured')
    .map((key: string) => ({
      ...offerings[key],
      ...outputs[key],
      dataCy: key,
    }));

  const appendTTCID = (url: string): string => {
    try {
      const newUrl = new URL(url);
      newUrl.searchParams.append('ttcid', `${isMobile ? 'mobile' : 'desktop'}-${postId.toString()}`);
      return newUrl.toString();
    } catch (error) {
      return '';
    }
  };
  const newUrl = appendTTCID(nextUrl);

  return (
    <CardWrapper
      className={className}
      $type={type}
      data-cy='ratesWidgetCard'
    >
      <div className='topSection'>
        <InstitutionImage
          image={institutionLogo}
          name={institutionName}
        />
        {isMobile ? (
          <InstitutionInfo
            rating={rating}
            productName={productName}
            institutionName={institutionName}
            widgetColor={widgetColor}
            isMobile
            insuranceDisclosure={insuranceDisclosure}
          />
        ) : (
          <>
            {offeringValues.map(({ value, date, valueLabel, dateLabel, dataCy }) => (
              <InfoItemValues
                key={valueLabel}
                title={value}
                info={date}
                titleLabel={valueLabel}
                infoLabel={dateLabel}
                dataCy={dataCy}
              />
            ))}
          </>
        )}
      </div>
      <div className='bottomSection'>
        {isMobile ? (
          <>
            {offeringValues.map(({ title, info, value, date, valueLabel, dateLabel }) => (
              <div
                className='offeringValues'
                key={valueLabel}
              >
                <InfoItemTooltips
                  title={title}
                  info={info.replace('$DEPOSIT_AMOUNT$', depositAmount.toString())}
                  widgetColor={widgetColor}
                  position='bottom-right'
                />
                <InfoItemValues
                  title={value}
                  info={date}
                  titleLabel={valueLabel}
                  infoLabel={dateLabel}
                />
              </div>
            ))}
            <OfferingInfoSection
              widgetColor={widgetColor}
              nextUrl={newUrl}
              offerDetails={offerDetails}
              reviewLink={reviewLink}
              institutionName={institutionName}
              trackingData={trackingData}
            />
            <PromotedOffer
              isPaid={isPaid}
              advertiserNote={advertiserNote}
            />
          </>
        ) : (
          <>
            <InstitutionInfo
              rating={rating}
              productName={productName}
              institutionName={institutionName}
              widgetColor={widgetColor}
              insuranceDisclosure={insuranceDisclosure}
            />
            <PromotedOffer
              isPaid={isPaid}
              advertiserNote={advertiserNote}
            />
            <OfferingInfoSection
              widgetColor={widgetColor}
              nextUrl={newUrl}
              offerDetails={offerDetails}
              reviewLink={reviewLink}
              institutionName={institutionName}
              trackingData={trackingData}
            />
          </>
        )}
      </div>
    </CardWrapper>
  );
};

export default RatesCard;
