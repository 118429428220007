import { GetStaticPropsResult } from 'next';

export class NotFoundError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'NotFoundError';
  }
}

export const logError = ({ name, message }: Error, placement?: string) => console.error(`${name || 'Error'}: ${placement || ''}${message || ''}`);

export const handleError = (error: Error): GetStaticPropsResult<any> => {
  logError(error);
  switch (true) {
    case error instanceof NotFoundError:
      return {
        notFound: true,
        revalidate: 60,
      };
    default:
      throw error;
  }
};
