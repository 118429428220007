import { color } from 'styles/globals';
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
const RatingStar = (col, type) => `
<svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label="Rating Star ${type}" role="img">
    <path d="M8.99918 13.239L14.2034 16.38L12.8223 10.46L17.4202 6.47685L11.3655 5.95474L8.99918 0.380005L6.63286 5.95474L0.578125 6.47685L5.1676 10.46L3.79497 16.38L8.99918 13.239Z" fill="${col}"/>
</svg>
`;

export const RatingStarHalf = `
<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.42105 12.8589L13.6253 16L12.2442 10.08L16.8421 6.09684L10.7874 5.57474L8.42105 0L6.05474 5.57474L0 6.09684L4.58947 10.08L3.21684 16L8.42105 12.8589Z" fill="#D1D1D6"/>
    <path d="M6.05474 5.57474L8.42105 0V12.8589L3.21684 16L4.58947 10.08L0 6.09684L6.05474 5.57474Z" fill="#111111"/>
</svg>
`;

export const RatingStarFull = RatingStar(color.primaryBlack, 'full');
export const RatingStarEmpty = RatingStar(color.secondaryLightGray, 'empty');