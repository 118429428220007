/* eslint-disable camelcase */
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { getApolloClient } from 'api/apolloClient';
import { NotFoundError } from 'api/errorHandling';
import { CardInfo } from 'interfaces/RecommendsWidget';
import { APIParameters } from './common';

const GET_CD_RATES = gql`
query getCDRates (
  $zip: String, $depositAmount: Float, $productTerm: [Int!], $page: Int, $pageSize: Int, 
  $listingType: ListingType, $ignoreBudget: Boolean, $pid: String
) {
  cd_rates(
    zipcode: $zip,
    deposit_amount: $depositAmount,
    product_term: $productTerm,
    page: $page,
    page_size: $pageSize,
    listing_type: $listingType,
    ignore_budget: $ignoreBudget,
    pid: $pid
  ) {
    institution {
      name
      logo_url
      reviews {
        overall_rating
        short_summary
        website_url
        link
      }
      insurance_information {
        type
        disclosure
      }
    }
    product {
      name
      term
      type
    }
    is_paid
    is_national
    is_local
    advertiser_notes
    apy
    effective_at
    min_to_open
    max_to_open
    rate
    ep_url
    pixel_url
  }
}
`;

interface reviews {
  overall_rating: number | null;
  short_summary: string | null;
  website_url: string | null;
  link: string | null;
}

export interface internalCDRatesResponse {
  institution: {
    name: string;
    logo_url: string;
    reviews: reviews;
    insurance_information: {
      type: string;
      disclosure: string;
    };
  };
  product: {
    name: string;
    term: number;
    type: string;
  };
  is_paid: boolean;
  is_national: boolean;
  is_local: boolean;
  advertiser_notes: string;
  apy: number;
  effective_at: string;
  min_to_open: number;
  max_to_open: number | null;
  rate: number;
  ep_url: string;
  pixel_url: string;
}

export interface CDRatesResponse {
  cd_rates: internalCDRatesResponse[];
}

const setCDRates = (toMap: CDRatesResponse) => {
  const insideToMap = toMap.cd_rates;
  const mapped: CardInfo[] = insideToMap.map(
    ({
      institution: {
        name: institutionName,
        logo_url: institutionLogo,
        reviews: { overall_rating: rating, short_summary: offerDetails, link: reviewLink },
        insurance_information: { disclosure: insuranceDisclosure },
      },
      product: { name: productName, term },
      apy,
      min_to_open: minToOpen,
      ep_url: nextUrl,
      effective_at: effectiveAt,
      advertiser_notes: advertiserNote,
      is_paid: isPaid,
    }: internalCDRatesResponse) => ({
      advertiserNote,
      apy,
      effectiveAt,
      institutionLogo,
      institutionName,
      insuranceDisclosure,
      isPaid,
      minToOpen,
      nextUrl,
      offerDetails,
      productName,
      rating,
      reviewLink,
      term,
    }),
  ) as CardInfo[];
  return mapped;
};

async function getCDRates({ depositAmount, productTerm, zip, page, pageSize }: APIParameters) {
  const apolloClient: ApolloClient<NormalizedCacheObject> = getApolloClient();

  const response = await apolloClient.query({
    context: { clientName: 'redVentures' },
    query: GET_CD_RATES,
    variables: {
      depositAmount,
      productTerm,
      zip,
      ...(page && { page }),
      ...(pageSize && { pageSize }),
      ignoreBudget: false,
      listingType: 'ALL',
      pid: 'myfi_fortune_api',
    },
  });
  const errorLabel = '[getCDRates]';
  if (response.errors) {
    throw new Error(`${errorLabel} ${JSON.stringify(response.errors)}`);
  }
  if (!response.data) {
    throw new NotFoundError(`${errorLabel} Could not find offers.`);
  }
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return setCDRates(response.data as CDRatesResponse);
  } catch (e) {
    throw new Error(`${errorLabel} ${(e as Error).stack}`);
  }
}

export default getCDRates;
