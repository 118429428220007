import styled from 'styled-components';
import { font } from 'styles/globals';
import { getColor, Theme } from 'styles/theme';

const LabelWrapper = styled.label<{ theme: Theme }>`
  font-family: ${font.graphikCond};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${getColor('textPrimary')};

  .required {
    color: ${getColor('textAccentSecondary')};
    margin-left: 10px;
  }
`;

export default LabelWrapper;
