import { useRouter } from 'next/router';
import Link from 'components/Globals/Base/Link';
import { DashboardIcon } from 'icons/DashboardIcon';
import { EditIcon } from 'icons/EditIcon';
import { useEffect, useState } from 'react';
import buildAdminBarUrls from './buildAdminBarUrls';
import AdminBarContainer from './AdminBarStyles';

export interface AdminBarProps {
  pageId: number;
}

const AdminBar = ({ pageId }: AdminBarProps) => {
  const router = useRouter();
  const { showAdminBar } = router.query;
  const [dashboard, setDashboardUrl] = useState('');
  const [edit, setEditUrl] = useState('');

  useEffect(() => {
    const hostUrl = window.location.host;
    const { dashboardUrl, editUrl } = buildAdminBarUrls(pageId, hostUrl);
    setDashboardUrl(dashboardUrl);
    setEditUrl(editUrl);
  }, [pageId]);

  if (!showAdminBar) {
    return null;
  }
  return (
    <AdminBarContainer>
      <ul className='admin-bar'>
        <Link
          href={dashboard}
          className='icon'
          target='_blank'
        >
          <DashboardIcon />
          Fortune
        </Link>
        <Link
          href={edit}
          className='icon'
          target='_blank'
        >
          <EditIcon />
          Edit
        </Link>
      </ul>
    </AdminBarContainer>
  );
};

export default AdminBar;
