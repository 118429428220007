import styled, { css } from 'styled-components';
import { font, breakpoint } from 'styles/globals';
import Heading from 'styles/typography/Heading';
import { getBodyStyle, getColor, Theme } from 'styles/theme';
import { arrowDown, arrowRight, arrowUp } from 'styles/icons';

export const Wrapper = styled.section<{ theme: Theme }>`
  display: flex;
  flex-flow: column;
  background-color: ${getColor('surfaceWhite')};
  border: 1px solid ${getColor('borderSecondary')};
  margin: 32px 0 !important;
  .loader {
    position: relative;
    z-index: 4;
    div {
      position: absolute;
      left:0;
      right:0;
      top: 30px;
      margin-left:auto;
      margin-right:auto;
    }
  }
  & > .pagination {
    align-self: center;
  }
  ul {
    padding: 0!important; //overwriting reset CSS "padding: revert" rule
  }
`;

export const Legal = styled.div<{ theme: Theme; $colorVar: string }>`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 16px;
  background-color: ${(props) => props.$colorVar};
  border-top: 1px solid ${getColor('borderSecondary')};
  flex-flow: column;
  a {
    &:focus {
      text-decoration: underline;
    }
    &:active {
      color: ${getColor('textPrimary')} !important;
    }
  }
  & > span {
    text-align: center;
    & > button {
      background: none;
      border: none;
      cursor: pointer;
    }
    & > a, & > button {
      color: ${getColor('textSecondary')};
      font-family: ${font.graphikCond};
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      text-decoration: none;
      &:hover,
      &:focus {
        text-decoration: underline;
      } 
      &:active {
        color: ${getColor('textPrimary')};
      }
      &:not(:last-child):after {
        content: "|";
        margin: 0px 8px !important;
        color: ${getColor('textSecondary')};
        font-family: ${font.graphikCond};
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
  @media (${breakpoint.mdMin}) {
    padding: 8px 16px;
    border-top: none;
    flex-flow: row;
    & > span {
      text-align: left;
      & > a, & > button {
        color: ${getColor('textWhite')};
        &:not(:last-child):after {
          color: ${getColor('textWhite')};
        }
      }
      & > a:hover {
        color: ${getColor('textWhite')};
      }
    }
  }

  @media (${breakpoint.mdMax}){
    border: none;
    & > span {
      text-align: left;
      & > a, & > button {
        color: ${getColor('textWhite')};
        &:not(:last-child):after {
          color: ${getColor('textWhite')};
        }
      }
    }
  }
`;

export const AdvDisclosureContent = styled.div<{ theme: Theme }>`
  display: flex;
  flex-flow: column;
  gap: 16px;
  & > p {
    ${getBodyStyle('graphikCond', { default: 'default' })};
    margin: 0;
  }
`;

export const PoweredBy = styled.a<{ theme: Theme }>`
  ${getBodyStyle('graphikCompact', { default: 'xsmall' })};
  color: ${getColor('textSecondary')} !important;
  text-decoration: none;
  text-align: center;
  svg {
    margin-left: 4px;
    vertical-align: text-bottom;
  }
  @media (${breakpoint.mdMin}) {
    text-align: right;
    color: ${getColor('textWhite')} !important;
  }
`;

export const HeaderContent = styled.div<{ $colorVar: string }>`
  background-color: ${(props) => props.$colorVar};
  padding: 16px;
  .savingsLogo > svg {
    margin-bottom: 24px;
  }
`;

export const Title = styled(Heading)<{ theme: Theme }>`
  margin: 0 !important; //overwrites headers styling (src/styles/headers.tsx)
  color: ${getColor('textWhite')};
  max-width: 100%;
`;

export const Filters = styled.form<{ theme: Theme }>`
  margin: 24px 16px !important;
  display: flex;
  flex-flow: wrap;
  gap: 8px;

  &.singleLine > div {
    @media (${breakpoint.mdMin}) {
      flex: calc( 33% - 4px );
    }
  }

  &.multiLine > div {
    @media (${breakpoint.mdMin}) {
      flex: calc( 50% - 4px );
      margin-bottom: 16px;
    }
  }

  & > div {
    position: relative;
    flex: 100%;
    & > .errorMessage {
      font-family: ${font.graphikCond};
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: ${getColor('shadeSemanticError700')};
      display: block;
      margin-top: 2px;

      @media (${breakpoint.mdMin}) {
        position: absolute;
        bottom: -24px;
        left: 0;
      }
    }
    * {
      width: 100%;
    }
  }

  label {
    font-family: ${font.graphikCond};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    display: inline-block;
    margin-bottom: 8px;
    text-transform: none;
  }

  .dollarSign {
    position: absolute;
    left: 16px;
    bottom: 20px;
    font-family: ${font.graphikCond};
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    width: fit-content;
  }

  input#depositAmount {
    padding-left: 32px;
  }

  input.error {
    border: 1px solid ${getColor('semanticsError')};
    &:focus-visible {
      outline: 4px solid ${getColor('semanticsError')}40;
    }
  }

  @media (${breakpoint.mdMin}) {
    margin-bottom: 0 !important;
  }
`;

export const InfoBar = styled.div<{ $type: string; theme: Theme }>`
  display: flex;
  justify-content: space-between;
  gap: 16px 8px;
  flex-flow: wrap;
  margin: 24px 16px !important;
  @media (${breakpoint.mdMin}) {
    gap: 16px;
  }
  & > div {
    display: flex;
    flex: 1;
    gap: 4px;
    flex-flow: nowrap;
    ${getBodyStyle('graphikCompact', { default: 'xsmall' })};
    letter-spacing: 0.01em;
    & > span {
      display: inline-block;
    }
  }
  ${(props) =>
    props.$type === 'cd-rates-widget' &&
    css`
    & > div: nth-child(1){
      flex: 1.4;
    }
    & > div: nth-child(3){
      flex: 0.8;
    }
    & > div: nth-child(5){
      flex: 1.2;
    }
    `}
`;

export const InfoItemWrapper = styled.div<{ $colorVar: string; theme: Theme }>`
  display: flex;
  gap: 8px;
  flex-flow: nowrap; 
  align-items: center;
  ${getBodyStyle('graphikCompact', { default: 'xsmall' })};
  letter-spacing: 0.01em;
  svg > path {
    fill: ${(props) => props.$colorVar};
  }
`;

export const SavingsAccountRatesWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  padding: 0;
  @media (${breakpoint.mdMin}) {
    padding-top: 16px;
    border-top: 1px solid lightgray;
  }
`;

export const CardWrapper = styled.li<{ theme: Theme; $type: string }>`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: ${getColor('surfaceWhite')};
  border: 1px solid transparent;
  position: relative;
  &:nth-child(odd) {
    background-color: ${getColor('surfaceSecondary')};
  }
  &:hover {
    border: 1px solid ${getColor('borderAccentPrimary')};
  }
  & > .topSection {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    gap: 16px;
    padding-bottom: 16px;
    border-bottom: none;
    & > div {
      flex: 1;
    }
    ${(props) =>
    props.$type === 'cd-rates-widget' &&
      css`
      & > div: nth-child(1){
        flex: 1.4;
      }
      & > div: nth-child(3){
        flex: 0.8;
      }
      & > div: nth-child(5){
        flex: 1.2;
      }
      `}
    .institutionInfo {
      text-align: right;
        div:nth-child(1) {
          justify-content: flex-end;
        }
    }
    .institutionImage {
      position: relative;
      flex: none;
      line-height: 0;
      display: flex;
      min-height: 62px;
      & > span {
        display: flex;
        align-items: center;
        line-height: normal;
        border: 4px solid ${getColor('surfaceWhite')};
        background: ${getColor('surfaceWhite')};
        img {
          width: auto;
          height: auto;
          min-width: auto;
          min-height: auto;
        }
      }
    }
    @media (${breakpoint.mdMin}) {
      gap: 0px 16px;
      border-bottom: 1px solid ${getColor('borderSecondary')};
      .institutionInfo {
        text-align: left;
        div:nth-child(1) {
          justify-content: flex-start;
        }
      }
      .institutionImage {
        flex: 1;
      }
    }
  }
  & > .bottomSection{
      display: flex;
      flex-flow: column;
      padding-top: 0;
      & > .offeringValues {
        display: flex;
        justify-content: space-between;
        padding: 8px 0;
        border-bottom: 1px solid ${getColor('borderSecondary')};
      }
    @media (${breakpoint.mdMin}) {
      flex-flow: row wrap;
      padding-top: 16px;
      justify-content: space-between;
      gap: 0 16px;
      & > div:nth-child(1){
        flex: 1;
      }
    }
  }
  .institutionInfo > div:nth-child(1) {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 8px;
  }
  p { 
    margin: 0!important;
  }
`;

export const ImagePlaceholder = styled.div<{ theme: Theme }>`
  font-family: ${font.graphikCond};
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px!important;
  width: 100%;
  min-height: 84px;
  background: ${getColor('surfaceWhite')};
  word-break: break-word;
`;

export const SavingRateInfoItem = styled.div<{ theme: Theme }>`
  position: relative;
  text-align: right;
  & > * {
    white-space: nowrap;
  }
  & > span {
    ${getBodyStyle('graphikCompact', { default: 'default' }, 'semiBold')};
    display: block;
    margin-bottom: 4px;
  }
  & > p {
    ${getBodyStyle('graphikCompact', { default: 'xsmall' })};
    margin: 0;
  }
  @media (${breakpoint.mdMin}) {
    text-align: left;
    & > span {
      font-size: 24.6px;
      margin-bottom: 4px;
      line-height: 50px;
    }
  }
`;

// TODO: is the $colorVar prop necessary here?
export const NextSection = styled.div<{ $colorVar: string }>`
  text-align: center;
  padding-top: 40px;
  width: 100%;
  & > div {
    justify-content: center;
  }
  @media (${breakpoint.mdMin}) {
    text-align: right;
    padding-top: 0;
    width: auto;
    & > div {
      justify-content: flex-start;
    }
  }
`;

export const OpenAccountButton = styled.a<{ theme: Theme }>`
  text-decoration: none;
  & > span{
    display: block;
    text-align: center;
    font-family: ${font.graphikCond};
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    padding: 8px;
    width: 100%;
    height: 33px;
    background: ${getColor('backgroundTertiary')};
    color: ${getColor('textWhite')} !important;
    text-transform: uppercase;
    position: relative;
    z-index: 2;
    letter-spacing: 0.01px;
    margin-bottom: 8px;
    &:hover{
      background: ${getColor('backgroundTertiary')}CC;
    }
    &:focus{
      background: ${getColor('backgroundTertiary')}80;
    }
    @media (${breakpoint.mdMin}) {
      display: inline-block;
      width: 121px;
      min-width: fit-content;
    }
  }
  &::before{
    content: '';
    position: absolute;
    inset: 0;
    z-index: 1;
  }
`;

export const OfferInfo = styled.p<{ theme: Theme; $colorVar: string; $show: boolean }>`
  display: flex;
  flex-flow: column;
  gap: 16px;
  margin: 0;
  flex: 100%;
  overflow: hidden;
  transition: all 0.3s ease;
  ${({ $show }) => `
    padding-top: ${$show ? '16px' : '0'};
    max-height: ${$show ? 'auto' : '0'};
    opacity: ${$show ? '1' : '0'};
  `};
  ${getBodyStyle('graphikCompact', { default: 'default' }, 'semiBold')};
  @media (${breakpoint.mdMin}) {
    border-bottom: none;
  }
  & > .readBankReview {
    a:not(.styled-custom-link) {
      ${getBodyStyle('graphikCompact', { default: 'small' }, 'semiBold')};
      text-decoration: none;
      text-transform: uppercase;
      color: ${(props) => props.$colorVar};
      position: relative;
      z-index: 2;

      &::after {
        ${arrowRight}
        margin-left: 3px;
        margin-bottom: 1px;
      }

      &:focus, 
      &:hover {
        text-decoration: underline;
      }
      &:active {
        color: ${getColor('textSecondary')};
      }
    }
  }
`;

export const ShowHide = styled.button<{ theme: Theme; $colorVar: string }>`
  border: none;
  margin: 0 auto !important;
  padding: 0;
  background: none;
  ${getBodyStyle('graphikCompact', { default: 'small' }, 'semiBold')};
  display: flex;
  justify-content: flex-end;
  color: ${(props) => props.$colorVar};
  cursor: pointer;
  position: relative;
  z-index: 2;
 
  &::after {
    ${arrowDown}
    margin: 6px 4px 4px 4px;
  }

  &.opened::after {
    ${arrowUp}
    margin: 9px 4px 4px 4px;
  }

  &:hover {
    text-decoration: underline; 
  }
  &:focus {
    text-decoration: underline;
    outline: 1px solid ${getColor('textLink')};
  } 
  &:active {
    color: ${getColor('textSecondary')};
    svg {
      stroke: ${getColor('textSecondary')};;
    }
  }

  @media (${breakpoint.mdMin}) {
    margin-right: 0 !important;
  }
`;

export const ProductName = styled.div<{ theme: Theme }>`
  ${getBodyStyle('graphikCompact', { default: 'xsmall' }, 'semiBold')};
  text-transform: uppercase;
`;

export const InstitutionName = styled.div<{ theme: Theme }>`
  ${getBodyStyle('graphikCompact', { default: 'xsmall' })};
`;

export const InsuranceDisclosure = styled.div<{ theme: Theme }>`
  ${getBodyStyle('graphikCompact', { default: 'xsmall' }, 'semiBold')};
`;

export const PromotedOfferWrapper = styled.div<{ theme: Theme }>`
  width: 100%;
  margin-top: 24px !important;
  text-align: center;
  &:empty {
    margin-top: 0!important;
  }
  .promotedTag {
    ${getBodyStyle('graphikCond', { default: 'default' }, 'semiBold')};
    text-align: center;
    letter-spacing: 0.01px;
    padding: 8px;
    width: 100%;
    background: ${getColor('brandSecondary')};
    color: ${getColor('textPrimary')};
    text-decoration: none;
    text-transform: uppercase;
    margin-bottom: 8px;
    margin-left: auto;
  }
  .advNote {
    ${getBodyStyle('graphikCompact', { default: 'small' })};
    width: 100%;
  }
  @media (${breakpoint.mdMin}) {
    width: auto;
    margin-top: 0 !important;
    text-align: right;
    .promotedTag {
      width: 121px;
      min-width: fit-content;
    }
    .advNote {
      width: 179px;
    }
  }
`;

export const NoResultsMessage = styled.p`
  font-family: ${font.graphikCond};
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  padding: 24px;
`;
