import styled from 'styled-components';
import { breakpoint, font } from 'styles/globals';
import { getColor, Theme } from 'styles/theme';
import Heading from 'styles/typography/Heading';

export const ModalWrapper = styled.div<{ theme: Theme }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 150vw;
  height: 150vh;
  background: ${getColor('backgroundTertiary')}4D;
  z-index: 999999;
`;

export const ModalTitle = styled(Heading)`
  text-align: center;
  margin: 0;

  @media (${breakpoint.mdMin}) {
    line-height: 38px;
  }
`;

export const Dialog = styled.div<{ theme: Theme }>`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
  background: ${getColor('surfaceWhite')};
  width: calc(100% - 48px);
  height: max-content;
  min-height: 200px;
  max-width: 100%;
  max-height: calc( 100vh - 48px);
  padding: 32px 16px;
  display: flex;
  align-items: stretch;
  flex-flow: column;
  gap: 16px;
  max-width: 1000px;

  & > div {
    font-family: ${font.graphikCond};
  }

  & > button {
    width: fit-content;
    align-self: center;
    padding: 8px 60px;
  }

  @media (${breakpoint.mdMin}) {
    padding: 40px;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 8px;
  top: 8px;
  padding: 0 !important;

  svg {
    width: 21px;
    height: 21px;
  }

  @media (${breakpoint.mdMin}) {
    right: 16px;
    top: 16px;
  }
`;

export const ModalButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 32px;
`;
