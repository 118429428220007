export const defaultZipCode = '92014';

export function isValidUSZipcode(value: string) {
  return /^([0-9]{5})(?:-([0-9]{4}))?$/.test(value);
}

const getUserZipCode = async () => {
  try {
    // fortune.com/geolocation has `Access-Control-Allow-Origin: *` so it can be called from any internal domains & localhost
    const response = await fetch('https://fortune.com/geolocation/');
    const zipCode = response.headers.get('geoip_postal_code');
    return zipCode && isValidUSZipcode(zipCode) ? zipCode : defaultZipCode;
  } catch (err) {
    return defaultZipCode;
  }
};

export default getUserZipCode;
