import { InfoIcon } from 'icons/InfoIcon';
import RawHtml from 'utils/miscUtils/rawHtml';
import { color as colors } from 'styles/globals';
import * as S from './InfoToggletipStyles';

interface InfoToggletipProps {
  content: string;
  position?: string;
  color?: string;
  dataCy?: string;
}

const InfoToggletip = ({ content, position, color = colors.primaryBlack, dataCy }: InfoToggletipProps) => (
  <S.InfoToggletipWrapper
    tabIndex={0}
    aria-label={content}
    role='tooltip'
    data-tooltip={content}
    $colorVar={color}
    $position={position}
    data-cy={dataCy}
    onKeyDown={(e) => {
      if (e.key === 'Escape') (e.target as HTMLElement).blur();
    }}
  >
    {RawHtml({ html: InfoIcon })}
  </S.InfoToggletipWrapper>
);

export default InfoToggletip;
