import { RatingStarFull, RatingStarEmpty, RatingStarHalf } from 'icons/RatingStar';
import RawHtml from 'utils/miscUtils/rawHtml';
import { RatingWrapper } from './RatingStyles';

const Rating = ({ score }: { score: number }) => {
  const scoreRounded = Math.round(score / 0.5) * 0.5;
  return (
    <RatingWrapper data-cy='RVWidgetsRating'>
      {[...(Array(Math.floor(scoreRounded)) as [])].map(() => RawHtml({ html: RatingStarFull }))}
      {!Number.isInteger(scoreRounded) && RawHtml({ html: RatingStarHalf })}
      {[...(Array(5 - Math.ceil(scoreRounded)) as [])].map(() => RawHtml({ html: RatingStarEmpty }))}
    </RatingWrapper>
  );
};

export default Rating;
