/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';
import { font } from 'styles/globals';
import { getColor, Theme } from 'styles/theme';
import { arrowRight, arrowLeft } from 'styles/icons';

const arrow = css<{ theme: Theme }>`
  padding: 4.5px;
  border-color: ${getColor('iconPrimary')};
  margin-bottom: -2px;
  border-width: 0 2px 2px 0;

  &:hover,
  &:focus {
    border-color: ${getColor('iconAccentPrimary')};
  }
  &:active {
    border-color: ${getColor('iconSecondary')};
  }
`;
export const PreviousArrow = styled.span<{ theme: Theme }>`
  ${arrowLeft}
  ${arrow}
`;

export const NextArrow = styled.span<{ theme: Theme }>`
  ${arrowRight}
  ${arrow}
`;

export const PaginationWrapper = styled.div<{ theme: Theme }>`
    font-family: ${font.graphikCond};
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    gap: 24px;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    padding: 16px 24px;
    & > div {
      line-height: 32px;
    }
    & > button {
      font-family: ${font.graphikCond};

      &:disabled {
        cursor: auto;
        span {
          border-color: ${getColor('textSecondary')};
        }
      }
    }
    input {
      font-family: ${font.graphikCond};
      font-weight: 600;
      font-size: 16px;
      line-height: 15px;
      margin: 0 16px;
      padding: 8px 16px;
      width: 70px;
      text-align: center;
      &.error {
        border: 1px solid ${getColor('semanticsError')};
        &:focus-visible {
        outline: 4px solid ${getColor('semanticsError')}40;
        }
      }
    }
`;
