import styled from 'styled-components';
import { breakpoint } from 'styles/globals';

// eslint-disable-next-line import/prefer-default-export
export const RatingWrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  align-items: center;
  @media (${breakpoint.mdMin}) {
    justify-content: flex-start;
  }
`;
