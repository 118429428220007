import Button from 'components/Globals/Base/Button';
import useClickOutside from 'hooks/useClickOutside';
import useLockBodyScroll from 'hooks/useLockBodyScroll';
import { Close } from 'icons/Close';
import { useEffect, useRef } from 'react';
import { ButtonVariant } from 'components/Globals/Base/Button/types';
import { CloseButton, Dialog, ModalButtons, ModalTitle, ModalWrapper } from './ModalStyles';

export interface ModalButtonProps {
  onClick: () => void;
  title: string;
  variant?: ButtonVariant;
  dataCy?: string;
}

interface ModalProps {
  className?: string;
  isOpened: boolean;
  title: string;
  children: string | JSX.Element;
  onClose: (e?: React.MouseEvent<HTMLButtonElement> | KeyboardEvent) => void;
  firstButton?: ModalButtonProps;
  secondButton?: ModalButtonProps;
}

const Modal = ({ className, isOpened = false, title, children, firstButton, secondButton, onClose }: ModalProps) => {
  const dialogRef = useRef<HTMLDivElement>(null);

  useClickOutside(dialogRef, onClose);
  useLockBodyScroll(isOpened);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (isOpened && e.key === 'Escape') onClose(e);
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpened, onClose]);

  return isOpened ? (
    <ModalWrapper
      className={className}
      role='dialog'
      aria-modal='true'
      aria-labelledby='modalTitle'
      aria-describedby='modalContent'
    >
      <Dialog
        ref={dialogRef}
        className='modal-wrapper'
      >
        <CloseButton
          onClick={(e) => onClose(e)}
          aria-label='Close'
        >
          <Close bold={false} />
        </CloseButton>
        <ModalTitle
          id='modalTitle'
          as='h2'
          $fontFamily='graphikCond'
          $size={{ default: 'level3', mdMin: 'level2' }}
          data-cy='modal-title'
        >
          {title}
        </ModalTitle>
        <div id='modalContent'>{children}</div>
        <ModalButtons>
          {firstButton && (
            <Button
              type='button'
              onClick={firstButton.onClick}
              variant={firstButton.variant || 'primary'}
              hasRightArrow={false}
              dataCy={firstButton?.dataCy}
            >
              {firstButton.title}
            </Button>
          )}
          {secondButton && (
            <Button
              className='second-btn'
              type='button'
              onClick={secondButton.onClick}
              variant={secondButton.variant || 'secondary'}
              hasRightArrow={false}
              dataCy={secondButton?.dataCy}
            >
              {secondButton.title}
            </Button>
          )}
        </ModalButtons>
      </Dialog>
    </ModalWrapper>
  ) : null;
};

export default Modal;
