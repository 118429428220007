import styled from 'styled-components';
import { font } from 'styles/globals';
import { getColor, Theme } from 'styles/theme';

const AdminBarContainer = styled.div<{ theme: Theme }>`
 ul.admin-bar {
  background: ${getColor('surfaceQuartenary')};
  bottom: 0;
  height: 32px;
  left: 0;
  line-height: 32px; 
  padding: 0 30px;
  position: fixed;
  width: 100%;
  z-index: 9999;
  
  svg {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    margin-bottom: -6px;
  }

  .icon {
    color: ${getColor('iconWhite')};
    display: inline-block;
    font-family: ${font.graphikCond};
    font-size: 14px;
    height: 31px;
    line-height: 31px;
    padding: 0 10px;
    text-decoration: none;
    transition: all 0.3s;

    &:hover {
      background: ${getColor('neutralSoftBlack')};
      color: ${getColor('textLink')};
    }
  }
}
`;

export default AdminBarContainer;
